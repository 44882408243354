import {Api} from "../ApiConfig";
import { ApiException } from "../ApiException";

const login = async (postData) => {
    try {
        const {data} = await Api().post("/login", postData)
        return data;
    } catch(error){
        console.log(error)
        return new ApiException(error.response.data.error || "Erro ao logar");
    }
}

export const AccessService = {
    login,
};