import axios from "axios";

export const Api = () => {
  const api = axios.create({
    baseURL: "https://linkjogodeouro.bet",
  });

  // Adicionando um interceptor de requisição
  api.interceptors.request.use(
    (config) => {
      // Adicione lógica de manipulação da requisição aqui, se necessário
      // console.log("Requisição enviada:", config);
      return config;
    },
    (error) => {
      // Adicione lógica de tratamento de erros na requisição aqui, se necessário
      // console.error("Erro na requisição:", error);
      return Promise.reject(error);
    }
  );

  // Adicionando um interceptor de resposta
  api.interceptors.response.use(
    (response) => {
      // Adicione lógica de manipulação da resposta aqui, se necessário
      // console.log("Resposta recebida:", response);
      return response;
    },
    (error) => {
      // Adicione lógica de tratamento de erros na resposta aqui, se necessário
      // console.error("Erro na resposta:", error);
      return Promise.reject(error);
    }
  );

  return api;
};
