import React, { useState, useEffect } from "react";
import Card from "../../../components/Global/Card";
import { BsPersonCheck } from "react-icons/bs";
import DataTable from "../../../components/Global/DataTable"; // Supondo que o DataTable está neste diretório
import { CustomService } from "../../../services/api/custom/CustomService";
import { toast } from "react-toastify";
import Modal from "../../../components/Global/Modal";
import { format } from "date-fns";
import loadingGif from "../../../assets/global/loadingGif.gif";

const Solicitacoes = () => {
  const [data, setData] = useState([]);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [toRemove, setToRemove] = useState(null);
  const [loading, setLoading] = useState(false);

  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [toAccept, setToAccept] = useState(null);

  const getData = async () => {
    try {
      setLoading(true);
      const resp = await CustomService.getRequest(`/tag-requests`);
      const respFormatted = resp.data.map((item) => {
        return {
          ...item,
          data: format(new Date(item.created_at), "dd/MM/yyyy"),
        };
      });

      setData(respFormatted);
    } catch (error) {
      toast.error("Erro ao listar contratantes.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!showRemoveModal) {
      setToRemove(null);
    }
  }, [showRemoveModal]);

  useEffect(() => {
    if (!showAcceptModal) {
      setToAccept(null);
    }
  }, [showAcceptModal]);

  const columns = [
    {
      title: "Nome",
      dataIndex: "tag_name",
      key: "tag_name",
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
    },
    {
      title: "Script",
      dataIndex: "script",
      key: "script",
    },
  ];

  const actions = {
    onAccept: (record) => {
      setToAccept(record);
      setShowAcceptModal(true);
    },
    onDelete: (record) => {
      setToRemove(record);
      setShowRemoveModal(true);
    },
  };

  const removeTag = async () => {
    try {
      setLoading(true);
      await CustomService.patchRequest(`/tag-request/${toRemove._id}/reject`);
      toast.success("Tag recusada com sucesso.");
      setShowRemoveModal(false);
      getData();
    } catch (error) {
      toast.error("Erro ao recusar tag.");
    } finally {
      setLoading(false);
    }
  };

  const acceptTag = async () => {
    try {
      setLoading(true);
      await CustomService.patchRequest(`/tag-request/${toAccept._id}/approve`);
      toast.success("Tag aceita com sucesso.");
      setShowAcceptModal(false);
      getData();
    } catch (error) {
      toast.error("Erro ao aceitar tag.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center gap-2 text-black h-[100%]">
          <img src={loadingGif} alt="loading" className="mx-auto w-[40px]" />
          <span>Carregando...</span>
        </div>
      ) : (
        <div className="flex flex-col w-full gap-3">
          {showRemoveModal && (
            <Modal
              buttonName="Excluir"
              title="Confirmar exclusão"
              handleModal={setShowRemoveModal}
              eventConfirmation={() => removeTag()}
              size="md:w-1/3"
            >
              <div className="flex flex-col justify-center items-center">
                <span>
                  Deseja recusar a tag <b>{toRemove.tag_name}</b> ?
                </span>
                <span className="text-[14px]"> Esta ação é irreversível</span>
              </div>
            </Modal>
          )}
          {showAcceptModal && (
            <Modal
              buttonName="Aceitar"
              title="Confirmar cadastro"
              handleModal={setShowAcceptModal}
              eventConfirmation={() => acceptTag()}
              size="md:w-1/3"
            >
              <div className="flex flex-col justify-center items-center">
                <span>
                  Deseja aceitar a tag <b>{toAccept.tag_name}</b> ?
                </span>
              </div>
            </Modal>
          )}
          <Card
            buttonActionPath="/solicitar"
            title="Solicitações de Tags"
            icon={<BsPersonCheck className="w-[25px] h-[25px]" />}
          >
            <div className="w-full">
              <DataTable size="100%" data={data} columns={columns} actions={actions} />
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default Solicitacoes;
