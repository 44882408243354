import { Api } from "../ApiConfig";
import { ApiException } from "../ApiException";
import { store } from "../../../store/store"; // Importe a store do Redux

// Função para obter o token do Redux
const getToken = () => {
    const state = store.getState();
    return state.auth.token;
};

const postRequest = async (urlRequest, postData) => {
    try {
        const token = getToken();
        const { data } = await Api().post(urlRequest, postData, {
            headers: {
                token: `${token}`,
            },
        });
        return data;
    } catch (error) {
        throw new ApiException(error.response.data.error || "Erro ao dar POST");
    }
};

const patchRequest = async (urlRequest, patchData) => {
    try {
        const token = getToken();
        const { data } = await Api().patch(urlRequest, patchData, {
            headers: {
                token: `${token}`,
            },
        });
        return data;
    } catch (error) {
        throw new ApiException(error.response.data.error || "Erro ao dar PATCH");
    }
}

const getRequest = async (requestWithParams) => {
    try {
        const token = getToken();
        const { data } = await Api().get(requestWithParams, {
            headers: {
                token: `${token}`,
            },
        });
        return data;
    } catch (error) {
        console.log(error.response.data.message);
        if(error.response.data.message === "Usuário não autênticado!") {
            store.dispatch({ type: "LOGOUT" });
        }
        throw new ApiException(error.response.data.error || "Erro ao dar GET");
    }
};

const deleteRequest = async (urlRequest) => {
    try {
        const token = getToken();
        const { data } = await Api().delete(urlRequest, {
            headers: {
                token: `${token}`,
            },
        });
        return data;
    } catch (error) {
        throw new ApiException(error.response.data.error || "Erro ao dar DELETE");
    }
};

export const CustomService = {
    postRequest,
    getRequest,
    deleteRequest,
    patchRequest
};