import React, { useState } from "react";
import { Table, Input, Button, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const DataTable = ({ size = "100%", data = [], columns = [], actions = {}, loading = false }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
  };

  const filteredData = data.filter((item) => {
    return Object.keys(item).some((key) => String(item[key]).toLowerCase().includes(searchText.toLowerCase()));
  });

  // Adicionando ações na última coluna
  const actionColumn = {
    title: "Ações",
    key: "actions",
    render: (text, record) => (
      <Space size="middle">
        {actions.onAccept && (
          <Button
            type="primary"
            style={{ backgroundColor: "#65C630" }}
            onClick={() => actions.onAccept(record)}
          >
            Aceitar
          </Button>
        )}
        {actions.onDelete && (
          <Button
            style={{ backgroundColor: "#ff4444", color: "white" }}
            type="danger"
            onClick={() => actions.onDelete(record)}
          >
            Recusar
          </Button>
        )}
      </Space>
    ),
  };

  // Adicionando a coluna de ações ao final das colunas existentes
  const columnsWithActions = [...columns, actionColumn];

  // Configurações de seleção em massa
  const rowSelection = {
    selectedRowKeys,
    onChange: handleSelectChange,
  };

  return (
    <div
      className="flex flex-col border-solid border-[1px] border-[#ebebeb] rounded-lg bg-white"
      style={{ width: size }}
    >
      {/* Campo de busca */}
      <div className="p-4">
        <Input
          placeholder="Buscar..."
          style={{
            fontSize: "16px",
            padding: "6px",
            border: "transparent",
            backgroundColor: "white",
            fontFamily: "mulish",
            width: "30%",
          }}
          prefix={<SearchOutlined />}
          onChange={handleSearch}
        />
      </div>

      {/* Tabela */}
      <Table
        // rowSelection={rowSelection} // Adicionando a seleção em massa
        columns={columnsWithActions} // Adicionando as colunas com ações
        dataSource={filteredData}
        loading={loading}
        pagination={false}
        className="rounded-lg"
        style={{ width: "100%", overflowX: "auto", backgroundColor: "white" }}
      />
    </div>
  );
};

export default DataTable;
