import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import { useNavigate } from "react-router-dom";

const Card = ({ title, size = "100%", children, icon, buttonActionPath, buttonActionFunction }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col border-solid border-[1px] border-[#ebebeb] rounded-lg bg-white`}
      style={{ width: size }}
    >
      <div className="flex justify-between p-4">
        <div className="flex gap-2 items-center  text-[20px]">
          {icon}
          {title && <span>{title}</span>}
        </div>
        {buttonActionPath && (
          <div>
            <Button type="primary" style={{ backgroundColor: "#232326" }} onClick={() => navigate(buttonActionPath)}>
              Adicionar nova Tag
            </Button>
          </div>
        )}
      </div>

      {children}
    </div>
  );
};

// Definindo tipos de prop
Card.propTypes = {
  size: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// Definindo valores padrão
Card.defaultProps = {
  size: "100%",
};

export default Card;
