import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./styles/main.scss";
import "./styles/_variables.scss";
import { store, persistor } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import PrivateRoute from "./components/Security/PrivateRoute/index.jsx";

import Login from "./pages/Login/index.jsx";
import Home from "./pages/Home/index.jsx";
import Layout from "./components/Global/Layout";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import * as serviceWorker from "../src/serviceWorker.js";
import Solicitacoes from "./pages/Views/Solicitacoes/index.jsx";
import Solicitar from "./pages/Registers/Solicitar/index.jsx";
serviceWorker.unregister();

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          {/* Incluindo o componente Template como o layout geral */}
          <Route element={<Layout />}>
            {/* Páginas de Acesso */}
            <Route path="/dashboard" element={<PrivateRoute element={<Home />} />} />
            <Route path="/solicitacoes" element={<PrivateRoute element={<Solicitacoes />} />} />

          </Route>
          {/* Redirecionar qualquer outra rota que não exista para a página de dashboard */}
          <Route path="/*" element={<Navigate to="/login" />} />
          <Route path="/solicitar" element={<Solicitar />} />
        </Routes>
      </Router>
    </PersistGate>
  </Provider>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ToastContainer autoClose={2000} />
    <App />
  </React.StrictMode>
);

reportWebVitals();
