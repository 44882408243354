import { Button } from "antd";
import React from "react";
import { connect } from "react-redux";

function Modal({ children, handleModal, title, size, buttonName, eventConfirmation }) {
  const handleCloseModal = () => {
    handleModal(false);
  };

  const handleConfirmAction = () => {
    eventConfirmation();
  };

  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className={`bg-white rounded-lg shadow-lg ${size ? size : "md:w-1/2"} flex flex-col justify-between`}>
        <div className="flex justify-between border-b-[1px] border-gray-300 px-4 py-2">
          <span className="">{title}</span>
          <button className=" text-gray-600 hover:text-gray-800" onClick={handleCloseModal}>
            X
          </button>
        </div>
        <div className="p-4">{children}</div>
        <div className="flex md:flex-row flex-col justify-end border-t-[1px] border-gray-300 px-4 py-2 md:gap-3 gap-2">
          <button
            className="text-red font-bold md:block border-[1px] border-gray px-4 rounded-[4px]"
            onClick={handleCloseModal}
          >
            Cancelar
          </button>
          <button
            type="primary"
            className="px-8 py-1.5 rounded-[4px] font-bold bg-[#232326] hover:bg-black text-white"
            onClick={handleConfirmAction}
          >
            {buttonName}
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  activate: () => dispatch({ type: "ACTIVE" }),
  disable: () => dispatch({ type: "DISABLE" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
