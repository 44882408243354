import Card from "../../components/Global/Card";

import React from "react";
import { RiDashboardLine } from "react-icons/ri";

const Home = () => {
  return (
    <div className="flex flex-col w-full gap-4">
      <Card title="Tags Adicionadas" icon={<RiDashboardLine className="w-[25px] h-[25px]" />}>
        <div className="px-5 pb-[20px]">
   
        </div>
      </Card>
    </div>
  );
};

export default Home;
