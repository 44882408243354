// src/serviceWorker.js

// Função para registrar o Service Worker
export const registerServiceWorker = () => {
    if ("serviceWorker" in navigator) {
      window.addEventListener("load", function () {
        navigator.serviceWorker
          .register("/sw.js")
          .then(function (registration) {
            console.log('ServiceWorker registration successful with scope: ', registration.scope);
          })
          .catch(function (err) {
            console.log('ServiceWorker registration failed: ', err);
          });
      });
    }
  };
  
  // Função para desregistrar o Service Worker (unregister)
  export const unregister = () => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister();
      });
    }
  };
  