import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { CustomService } from "../../../services/api/custom/CustomService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import loadingGif from "../../../assets/global/loadingGif.gif";

import "./styles.scss";

function RegisterSolicitar() {
  const navigate = useNavigate();
  const [tagSolicitada, setTagSolicitada] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const body = {
        tag_name: data.nome,
        script: data.script,
      };
      console.log(data);
      await CustomService.postRequest("/tag-request", body);
      toast.success("Tag solicitada com sucesso!");
      setTagSolicitada(true);
    } catch (error) {
      toast.error("Erro ao solicitar cadastro de tag.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="flex flex-col items-center justify-center gap-2 text-white">
          <img src={loadingGif} alt="loading" className="mx-auto w-[40px]" />
          <span>Enviando, aguarde...</span>
        </div>
      ) : !tagSolicitada ? (
        <div className="flex flex-col w-full gap-3">
          <form onSubmit={handleSubmit(onSubmit)} className="flex flex-wrap gap-3 w-full">
            {/* Input de nome */}
            <div className="w-full md:w-[100%]">
              <label className="block mb-1 text-white">Nome da Tag:</label>
              <input
                type="text"
                placeholder="Digite um nome para a tag"
                className="border border-gray-300 p-2 w-full text-[15px]"
                {...register("nome", { required: "O nome é obrigatório" })}
              />
              {errors.nome && <p className="text-red-500 text-sm">{errors.nome.message}</p>}
            </div>

            {/* Textarea para o script */}
            <div className="w-full md:w-[100%]">
              <label className="block mb-1 text-white">Script:</label>
              <textarea
                placeholder="Cole seu script/pixel aqui"
                className="border border-gray-300 p-2 w-full resize-y text-[15px]"
                rows="6"
                {...register("script", {
                  required: "O script é obrigatório",
                  validate: (value) =>
                    (value.startsWith("<script") && value.endsWith("</script>")) ||
                    "O script deve começar com <script e terminar com </script>",
                })}
              />
              {errors.script && <p className="text-red-500 text-sm">{errors.script.message}</p>}
            </div>

            {/* Botão de enviar */}
            <div className="w-full flex justify-center">
              <input
                type="submit"
                value="Solicitar Cadastro da Tag"
                className="bg-[#65C630] text-white p-2 mt-4 cursor-pointer text-[14px]"
              />
            </div>
          </form>
        </div>
      ) : (
        <div className="flex flex-col justify-start items-center text-white gap-2">
          <span className="text-[20px]">Sua tag foi solicitada com sucesso!</span>
          <span className="text-[16px]">Aguarde que logo iremos analisar e autorizar</span>
          <button
            className="bg-[#FFC22A] text-black rounded-[6px] px-3 py-2 mt-3"
            onClick={() => window.location.reload()}
          >
            Solicitar outra Tag
          </button>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  role: state.auth.role,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  exit: () => dispatch({ type: "LOGOUT" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterSolicitar);
