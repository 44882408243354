import React, { useState, useEffect, useRef } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { LogoutOutlined, MenuOutlined, CloseOutlined } from "@ant-design/icons";
import { AiOutlineUser, AiOutlineCalendar, AiOutlineTeam } from "react-icons/ai";
import { connect } from "react-redux";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import Logo from "../../../assets/global/Logo.png";
import { ImList2 } from "react-icons/im";
import { IoIosPricetags } from "react-icons/io";
import "./styles.scss";

const Layout = ({ exit, user }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation(); // Hook para obter a localização atual

  const items = [
    {
      key: "grp1",
      label: "Menu",
      type: "group",
      children: [
        {
          key: "sub1",
          label: "Solicitações",
          icon: <IoIosPricetags />,
          onClick: () => navigate("/solicitacoes"),
        },
      ],
    },
  ];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleExit = () => {
    exit();
  };

  // Fechar o menu ao clicar fora dele
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    if (isMenuOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  // Determinar a chave selecionada com base na localização atual
  const selectedKey = items
    .flatMap((item) => [item, ...(item.children || [])])
    .find((item) => location.pathname.includes(item.key))?.key;

  return (
    <div className="flex w-full relative flex-col md:flex-row">
      {/* START: MENU LATERAL */}
      <div
        ref={sidebarRef}
        className={`sidebar-container flex flex-col md:justify-between w-[100%] md:w-[280px] fixed top-0 left-0 h-[100vh] bg-[#09090B] z-50 transform ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:relative md:translate-x-0 w-[256px]`}
      >
        <div className="flex flex-col gap-2 md:gap-0 menu-content">
          <div className="md:h-[60px] w-full flex flex-col justify-center items-center">
            <img
              onClick={() => navigate("/solicitacoes")}
              src={Logo}
              className="hidden md:flex md:w-[180px] w-[150px] hover:cursor-pointer"
              alt="logo"
            />
          </div>

          <div className="flex w-full justify-between md:hidden px-5">
            {/* <img src={Logo} className="w-[120px] curs" alt="logo" /> */}
            <button onClick={toggleMenu} className="text-[16px] text-white flex gap-2 items-center">
              <CloseOutlined className="text-[15px]" />
              <span>Fechar</span>
            </button>
          </div>

          <Menu
            onClick={() => closeMenu()}
            className="custom-menu pt-2"
            selectedKeys={[selectedKey]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            items={items}
          />
        </div>
        <div className="custom-menu-config w-full">
          <ul>
            <li onClick={handleExit}>
              <LogoutOutlined />
              <span>Sair</span>
            </li>
          </ul>
        </div>
      </div>
      {/* END: MENU LATERAL */}

      {/* START: LATERAL DIREITA */}
      <div className="w-full flex flex-col">
        {/* START: MENU SUPERIOR */}
        <div className="w-full md:h-[60px] h-[50px] px-2 md:px-8 flex justify-between md:justify-end items-center bg-[#09090B] text-white">
          <div className="hamburger-icon md:hidden" onClick={toggleMenu}>
            {isMenuOpen ? <CloseOutlined className="text-2xl" /> : <MenuOutlined className="text-2xl" />}
          </div>
          <img src={Logo} className="md:hidden md:w-[180px] w-[150px]" alt="logo" />
          <div className="w-[35px] h-[35px] mr-2 rounded-full bg-white flex justify-center items-center">
            <AiOutlineUser style={{ color: "black", fontSize: "25px" }} />
          </div>
          <div className="flex flex-col items-start">
            <span>{user}</span>
            <span className="text-[12px]">Administrador</span>
          </div>
        </div>
        {/* START: MENU SUPERIOR */}

        {/* START: CONTEÚDO DA PÁGINA */}
        <main className="md:p-4 bg-[#ededed] h-[92vh] overflow-y-auto">
          <Outlet />
        </main>
        {/* END: CONTEÚDO DA PÁGINA */}
      </div>
      {/* END: LATERAL DIREITA */}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  role: state.auth.role,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  exit: () => dispatch({ type: "LOGOUT" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
