import React from "react";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { connect } from "react-redux";
import { AccessService } from "../../services/api/access/AccessService";
import { ApiException } from "../../services/api/ApiException";
import Logo from "../../assets/global/Logo.png";
import { toast } from "react-toastify";

import "./styles.scss";

function Login({ login, isAuthenticated }) {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    login: "",
    password: "",
    showPassword: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/solicitacoes");
    }
  }, []);

  const validationSchema = Yup.object().shape({
    login: Yup.string().required("O login é obrigatório"),
    password: Yup.string().min(3, "A senha deve ter pelo menos 3 caracteres").required("A senha é obrigatória"),
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await validationSchema.validate(values, { abortEarly: false });

        AccessService.login({
          username: values.login,
          password: values.password,
        }).then((resp) => {
          if (resp instanceof ApiException) {
            toast.error(resp.message);
          } else {
            login({
              isAuthenticated: true,
              user: resp.data.user.username,
              token: resp.data.token,
              role: "admin",
              error: null,
            });
            navigate("/solicitacoes");
          }
        });
    } catch (validationErrors) {
      const newErrors = {};

      // Verifique se validationErrors é um array antes de usar forEach
      if (Array.isArray(validationErrors.inner)) {
        validationErrors.inner.forEach((error) => {
          newErrors[error.path] = error.message;
        });
      } else {
        // Trate o erro de outra forma, se necessário
        newErrors.global = "Erro de validação.";
      }

      setErrors(newErrors);
    }
  };

  const styles = {
    "input-label": {
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      width: "100%",
      color: "white",
    },

    input: {
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: "white",
      },
    },
  };

  return (
    <div className="w-[100%] h-[100vh] flex flex-col">
      <div className="h-[1%] w-full bg-[#09090B]"></div>
      <div className=" bg-[#09090B] h-[98%] flex flex-col justify-center items-center gap-10">
        <div className="flex gap-3 justify-center items-center">
          <img src={Logo} className="w-[320px]" />
        </div>
        <div className="login-form md:w-[30%] w-[80%]">
          <form onSubmit={handleSubmit}>
            <label className="text-[#ebebeb]">Login</label>
            <TextField
              style={{ marginBottom: "20px", marginTop: "5px", backgroundColor: "white", borderRadius: "7px" }}
              variant="outlined"
              fullWidth
              id="login"
              value={values.login}
              onChange={handleChange("login")}
              autoComplete="new-password"
              placeholder="Insira seu login"
              InputProps={{
                classes: { input: styles.input },
              }}
              error={Boolean(errors.login)}
              helperText={errors.login || ""}
            />
            <label className="text-[#ebebeb]">Senha</label>
            <TextField
              style={{ marginBottom: "20px", marginTop: "5px", backgroundColor: "white", borderRadius: "7px" }}
              placeholder="Insira sua senha"
              variant="outlined"
              fullWidth
              id="password"
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={handleChange("password")}
              autoComplete="new-password"
              InputProps={{
                classes: { input: styles.input },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(errors.password)}
              helperText={errors.password || ""}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              style={{
                width: "100%",
                backgroundColor: "#65C630",
                color: "white",
                borderRadius: "4px",
                fontSize: "16px",
                textTransform: "capitalize",
                padding: "12px 0px",
              }}
            >
              Entrar
            </Button>
          </form>
        </div>
      </div>
      <div className="h-[1%] w-full bg-[#09090B]"></div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  login: (userData) => dispatch({ type: "LOGIN_SUCCESS", payload: userData }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
