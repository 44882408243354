// persistConfig.js
import { createStore, applyMiddleware, compose } from "redux";
import storage from "redux-persist/lib/storage"; // Utilizaremos o localStorage como local de armazenamento
import { persistStore, persistReducer } from "redux-persist";
import multi from "redux-multi";
import rootReducer from "../reducers/rootReducer";
import promise from "redux-promise";
import {thunk} from 'redux-thunk';

const persistConfig = {
  key: "root", // A chave raiz para a persistência
  storage, // O local de armazenamento (localStorage)
  blacklist: ["error", "socket"], // Não persistir o estado de erro
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk, promise, multi)));
const persistor = persistStore(store);

export { store, persistor };
