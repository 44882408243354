import React from "react";
import Card from "../../../components/Global/Card";
import { AiOutlineTeam } from "react-icons/ai";
import RegisterSolicitar from "../../../components/Forms/RegisterSolicitar";
import Logo from "../../../assets/global/Logo.png";
import { useParams } from "react-router-dom";

function Solicitar() {
  return (
    <div className="flex flex-col w-full gap-3 bg-[#09090B] h-[100vh] justify-center items-center">
      <img
        src={Logo}
        className="hidden md:flex md:w-[380px] w-[150px]"
        alt="logo"
      />
      <div className="p-4 flex flex-col gap-5 w-full md:w-[50%] max-h-[80%] overflow-auto">
        <RegisterSolicitar />
      </div>
    </div>
  );
}

export default Solicitar;
